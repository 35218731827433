.Footer_title {
  color: #3f3f3f;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.Footer_List {
  list-style: none;
  padding: 0;
  line-height: 1.5;
}

 .about-link li{
    color: #8F8F8F !important;
}

#Email {
  color: #3f3f3f;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

#Email img:first-child {
margin-top: 4px;
  }

@media screen and (max-width: 425px) { 
    .Footer_List {
    
        text-align: center;
    
    }
    .Footer_title  p {
        text-align: center !important;
    }

    .footer_desc {
        text-align: center;
    }
    .Logo {
        text-align: center;
        padding-bottom: 18px;
    }

    .caption span {
        font-size: 14px;
        font-weight: 600;

    }
    .Footer_title {
        text-align: center;
    }


  }

/* .FooterContainer {
    width: 100%;
    height: 100%;
    padding-left: 102px;
    padding-Right: 102px;
    padding-Top: 39px;
    padding-Bottom: 39px;
    flex-Direction: column;
    justify-Content: center;
    align-Items: center;
    gap: 10px;
    display: inline-flex;
    background-color: white;
}

.FooterSubContainer {
    justify-Content: flex-start;
    align-Items: flex-start;
    gap: 104px;
    display: inline-flex;
}

.LogoContainer {
    flex-Direction: column;
    justify-Content: flex-start;
    align-Items: flex-start;
    gap: 34px;
    display: inline-flex;
}

.LogoContainer1 {
    width: 150px;
    height: 24px;
}

.EmailContainer {
    justify-Content: flex-start;
    align-Items: center;
    gap: 8px;
    display: inline-flex;
}

.EmailLogo {
    width: 24px;
    height: 24px;
    position: relative;
}

.EmailName {
    justify-Content: flex-start;
    align-Items: center;
    gap: 30px;
    display: flex;
}

.ServiceContainer {
    justify-Content: flex-start;
    align-Items: flex-start;
    gap: 102px;
    display: flex;
}

.ServiceSubContainer {
    flex-Direction: column;
    justify-Content: flex-start;
    align-Items: flex-start;
    gap: 16px;
    display: inline-flex;
}

.Item {
    justify-Content: flex-start;
    align-Items: center;
    gap: 30px;
    display: inline-flex;
}

.ItemName {
    color: #8F8F8F;
    font-Size: 16px;
    font-Family: "Poppins";
    font-Weight: 300;
    word-Wrap: break-word;
}

.ItemHeader {
    color: #3F3F3F;
    font-Size: 16px;
    font-Family: "Poppins";
    font-Weight: 600;
    word-Wrap: break-word;
}

.LocationContainer {
    flex-Direction: column;
    justify-Content: flex-start;
    align-Items: flex-start;
    gap: 16px;
    display: flex;
}

.LocationItem {
    width: 378px;
    color: #8F8F8F;
    font-Size: 16px;
    font-Family: Montserrat;
    font-Weight: 400;
    word-Wrap: break-word;
} */
