.Question_container {
  // display: flex;
  // justify-content: center;
  // align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.card-body {
  text-align: center;
  color: #3F3F3F;
// my css 
  // width: 85%;
}

.text_in_Question {
  color: #3F3F3F;
  font-size: 1.5rem;
}

.Questions_Body {
  text-align: center; /* Center the content within Questions_Body */
  display: flex;
    // justify-content: space-between;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.Questions {
  font-size: 16px;
  display: flex;
  align-items: center;
}


.Question_text_color{
    margin-left: 2rem;
    font-size: 1.9rem;
    background:linear-gradient(34deg, #00d0ff 31.29%, #1858b5 94.55%); 
    -webkit-background-clip: text;
    color: transparent;

}

.Question_text_color {
  background: linear-gradient(34deg, #00d0ff 31.29%, #1858b5 94.55%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}
.body_borer{
    margin-top: 1rem;
    // border: 1px solid #EEEEEE;
    border-radius: 10px;
}

.Questions {
  text-align: left;
  line-height: 1.5;
}

.Questions_Body p {
  text-align: left;
  padding: 0px 383px;
  // padding: 0px 35%;
  line-height: 1.6;
}

@media screen and (max-width: 425px) { 
.Questions_Body  {
  align-items: center;
}
.Questions_Body p {
  padding: unset !important;
}
  }
  @media  screen and (min-width: 455px) and (max-width:768px)  { 
    .Questions_Body p {
      text-align: left;
      padding: 0% 5%;
      line-height: 1.6;
  }
  }
