.text_contact{
    text-align: end;
    padding-right: 2rem;
    color: #3F3F3F;
}
.Contact_Inpute{
    width: 100%;
    padding: 0.5rem;
    margin-top: 1rem;
    border-radius: 8px;
border: 1px solid #EEE;
}

.contact_send{
    border-radius: 8px;
border: 1px solid #EEE;
background: var(--blue-gradient, linear-gradient(34deg, #00D0FF 31.29%, #1858B5 94.55%));
box-shadow: 0px 2px 16px 0px rgba(1, 205, 253, 0.30);
}
.form_container{
    text-align: center;
    justify-content: center;
}

@media screen and (max-width: 425px) { 
    /* .boxes {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
    
    } */

    input {
        text-align: center;
    }
  }