.heading_test {
  color: #3f3f3f;

  text-align: center;
  font-family: Poppins;
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.tile_About {
  color: #3f3f3f;

  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.Colored_text2 {
  font-family: Poppins;
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.custom_card_body {
  text-align: left;
  display: flex;
  justify-content: space-between;
  border-radius: 8px;
  border: 1px solid #eee;

  background: #fff;

  box-shadow: 0px 2px 16px 0px rgba(1, 205, 253, 0.3);
  margin: 0.5em;
}

.About_center .col-md-4 {
  margin: 1rem;
  border-radius: 8px;
  border: 1px solid #eee;
  background: #fff;
  box-shadow: 0px 2px 16px 0px rgba(1, 205, 253, 0.3);
}
.leftDiv_middle {
  margin-top: 25%;
}
.stages_cards {
  display: flex;
  justify-content: center;
}
.Custom_card_text {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.testingTitle {
  color: #3f3f3f;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.testingParagraph {
  color: #8f8f8f;
  text-align: center;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 170%; /* 34px */
}
.Testing_card_circle {
  height: 60px;
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 35.398px;
  border: 1px solid #00d0ff;
  background: #fff;
  box-shadow: 0px 1.41593px 11.32743px 0px rgba(1, 205, 253, 0.1);
  font-size: 1rem;
  margin-bottom: 1rem
}

.testing_body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 425px) { 
.col-md-4 {
  text-align: center;
  margin: 1rem;
}
.leftDiv_middle {
  text-align: center;
}
}

@media  screen and (min-width: 455px) and (max-width:768px)  { 
  .leftDiv_middle {
 text-align: center;
  }
}