.circle {
  width: 70%;
  padding-bottom: 30%;
  background-color: transparent;
  border-radius: 50%;
  margin-top: 5%;
  position: relative;
  transform: rotate(-18.879deg);
  border: 3px solid rgba(1, 204, 253, 0.1);
}

.message {
  position: absolute;
  transform: translate(-50%, -50%);
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 8px 16px 0px rgba(1, 205, 253, 0.1);
  color: #fff; /* Text color for all messages */
  padding: 10px; /* Padding for all messages */
  border-radius: 5px; /* Border radius for all messages */
  font-weight: bold; /* Font weight for all messages */
  padding: 0;
}

.message:nth-child(1) {
  top: -11%;
  left: 50%;
  transform-origin: center bottom;
  transform: translateX(-50%) rotate(90deg);
}

.message:nth-child(2) {
  top: 33%;
    left: 95%;
    transform-origin: left center;
    transform: translateY(-44%) rotate(357deg);
}

.message:nth-child(3) {
  bottom: -5%;
  left: 61%;
  transform-origin: center top;
  /* transform: translateX(-50%) rotate(270deg); */
}

.message:nth-child(4) {
  top: 15%;
  left: 12%;
  transform-origin: right center;
  transform: translateY(-50%) rotate(0deg);
}

.message:nth-child(5) {
  top: 91%;
  left: 25%;
}

.message:nth-child(6) {
  right: 2%;
  bottom: 13%;
}
.Circle_continer {
  display: flex;
  justify-content: center;
  align-items: center;
}
.Circle_Button_container {
  text-align: center;
  display: flex;
  justify-content: center;
}
#button {
  height: 2rem;
  border: 1px solid;
  text-align: center;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  border-radius: 10px;
}
#button:hover {
  color: black;
  cursor: pointer;
  background: linear-gradient(34deg, #00d0ff 31.29%, #1858b5 94.55%);
}

.Circle_Button_container {
  margin-top: 5%;
}

/* @media screen and (min-width:455px) and (max-width:768px) {  */
@media  screen and (min-width: 455px) and (max-width:768px)  { 
.message:nth-child(1) {
  top: -23% !important;
}
.message:nth-child(6) {
  right: -9% !important;
}

.message:nth-child(3) {
  bottom: -16%;
}
.message:nth-child(4) {
  top: 12%;
    left: 12%;
}
.carcle_text_continer h1 {
  font-size: 32px;
}

}