
.centered-container,.tex_in_Landing {
  text-align: center; /* If you want the content centered within the container */
}

 .body_of_Landing_section2{
    display: flex;
    padding: 2rem;
    justify-content: space-between;
    border-radius: 8px;
background: #FFF;

box-shadow: 0px 2px 16px 0px rgba(1, 205, 253, 0.30);



}
.left_side{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: end;
}
.card{
    border: 0px;
}
 #Landing_circle{
       margin-bottom: 3rem;
   
 }
 
@media (max-width: 769px) {
       #Landing_circle{
       margin-bottom: 8rem;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
 }

 .right_side {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: end;
}

.Contact_Inpute {
  width: 70%;
  text-align: center;
}


  }
  .Profile{
    height: 3rem;
    width: 3rem;
    margin-right: 2rem;
    border-radius: 50%;
  }
  .title_profile{
    display: flex;
    /* justify-content: space-around; */
    justify-content: center;
  }

  .boxes{
    border-radius: 20px;
    background: #FFF;
    /* box-shadow: 0px 2px 16px 0px rgba(1, 205, 253, 0.30); */
}

.names_landing_page{
    color: #3F3F3F;

font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: normal;
text-align: center;
}

.paragraph_about{
    color: #666;
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 170%; /* 27.2px */
}
.position_landing_page{
    color: #8F8F8F;

font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
    
}

@media screen and (max-width: 425px) { 
  .right_side {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: end;
  }
  .full-row {
    justify-content: center;
  }


  .left_side {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
  }
  .right_side {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center !important;
}
}

.profile-card {
  background-color: #fff;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    /* min-width: 160px; */
    /* width: auto; */
    /* white-space: nowrap; */
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.3);
    z-index: 99999;
    border-radius: 0.75rem;
}