

.tile_About{
    color: #3F3F3F;

font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 300;
line-height: normal;
}
.Heading_About{
    color: #3F3F3F;

font-family: Poppins;
font-size: 19px;
font-style: normal;
font-weight: 600;
line-height: normal;
}
.Anout_paragraph{
    color: #8F8F8F;

font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 300;
line-height: 170%;
}

.About_center{
    justify-content: center;
}

 @media screen and (max-width: 425px) { 
    .boxes {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
    
    }

    h6 {
        text-align: center;
    }
  }

  @media  screen and (min-width: 455px) and (max-width:768px)  {  
    .row {
        /* width: 84%; */
    }
    .row>* {
        width: 90%;
    }
  }