body {
      font-family: 'Poppins', sans-serif !important;

 color: #8F8F8F;
font-size: 16px;
font-style: normal;
font-weight: 300;
background-color: white;
line-height: normal;
}
*{
    font-family: 'Poppins', sans-serif !important;
}
div,h1,h2,h3,h4,h5,h6,p,span{
    font-family: 'Poppins', sans-serif !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
 .Colored_text {
    background: linear-gradient(34deg, #00d0ff 31.29%, #1858b5 94.55%);
    -webkit-background-clip: text; /* For older webkit browsers */
    background-clip: text;
    color: transparent;
    text-fill-color: transparent;
  }