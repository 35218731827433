.Team_Services{
    text-align: center;
    display: flex;
    justify-content: center;
}
.team_service_card{
    display: flex;
    justify-content: center;
}

@media screen and (max-width: 641px) {
    .team_service_card {
        display: block;
    }
}
